import gql from "graphql-tag";

export const GET_REQUEST_CONSTANTS = gql`
  query GetRequestConstants($where: String){
    LDPConfigQueryGroup {
      RequestConstant(where: $where, limit: 999) {
        RequestConstantId
        ConstantName
        ConstantValue
        SubVerticalName
        VerticalName
        CreatedDate
        ModifiedDate
        UserId
      }
    }
  }
  `;