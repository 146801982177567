import { useLazyQuery } from "@apollo/react-hooks";
import { Grid, Hidden, Paper } from "@material-ui/core";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import React, { useEffect } from "react";
import { GET_REQUEST_CONSTANTS } from "../../common/models/requestConstants";
import { GetRequestConstants } from "../../common/models/types/GetRequestConstants";
import { dateToPreferredTimezone } from "../../common/utils/date";
import { ContentLoading, useModal } from "../../components";
import { createDateFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";
import LDPUIDataTable from "../../components/LDPUIDataTable";

interface RequestConstantProps {
  addTab: Function;
  /* dispatch:Function; */
}

export default (props: RequestConstantProps) => {
  const [
    getRequestConstants,
    { data, error, loading, called, refetch },
  ] = useLazyQuery<GetRequestConstants>(GET_REQUEST_CONSTANTS);

  const { addTab } = props;

  const columnsMUI = [
    {
      name: "RequestConstantId",
      label: "ID",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "ConstantName",
      label: "Constant Name",
      options: {
        viewColumns: false,
        filter: true,
        sort: false,
        ...stringColumnFilter,
      },
    },
    {
      name: "ConstantValue",
      label: "Value",
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => (
          <Paper
            onClick={() => {
              openModal({
                title: "Constant Value",
                icon: <DonutSmallIcon />,
                iconColor: "green",
                content: <Paper style={Style.CodeModal}>{value}</Paper>,
              });
            }}
            style={Style.CodeWrapper}
          >
            {value}
          </Paper>
        ),
        setCellProps: () => {
          return {
            style: {
              padding: "5px",
            },
          };
        },
      },
    },
    {
      name: "VerticalName",
      label: "Vertical",
      options: {
        viewColumns: false,
        filter: true,
        sort: false,
        ...stringColumnFilter,
      },
    },
    {
      name: "SubVerticalName",
      label: "Sub-Vertical",
      options: {
        viewColumns: false,
        filter: true,
        sort: false,
        ...stringColumnFilter,
      },
    },
    {
      name: "UserId",
      label: "User",
      options: {
        viewColumns: false,
        filter: true,
        sort: false,
        ...stringColumnFilter,
      },
    },
    {
      name: "ModifiedDate",
      label: "Modified Date",
      options: {
        //     viewColumns: false,
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const dateValue =
            value || tableMeta.tableData[tableMeta.rowIndex].CreatedDate;
          return dateValue
            ? dateToPreferredTimezone(dateValue, "yyyy-MM-dd")
            : "";
        },
        ...createDateFilter("yyyy-MM-dd")
      },
    },
  ];

  const { Modal, closeModal, openModal, setContent } = useModal();

  useEffect(() => {
    getRequestConstants();
  }, []);

  const options: any = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
  };

  return (
    <Grid container spacing={1}>
      <Hidden only="sm">
        <Grid item xs md={6}>
          {" "}
        </Grid>
      </Hidden>
      <Grid item xs={12} sm={6} md={3}></Grid>
      <Grid item xs={12} sm={6} md={3}></Grid>
      <Grid item xs={12}>
        {loading && <ContentLoading showTip={true} />}
        <LDPUIDataTable
          ldpTableId="request-constant-list"
          restoreFilters={true}
          title={"Request Constants"}
          data={data?.LDPConfigQueryGroup?.RequestConstant}
          columns={columnsMUI}
          options={options}
        />
      </Grid>
      <Modal />
    </Grid>
  );
};

const Style = {
  Paper: {
    marginTop: "50px",
    padding: "30px",
    width: "100%",
  },
  TrafficLight: {
    display: "inline-block",
    width: "16px",
    height: "16px",
    lineHeight: "24px",
    border: "#000 1px solid",
    borderRadius: "50%",
    margin: "auto 0",
  },
  CodeWrapper: {
    height: "120px",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
  CodeModal: {
    height: "60vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
};
